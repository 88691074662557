<template>
  <!-- 拼团详情页 -->
  <div v-loading="loading">
    <!-- 头部定位区域 -->
    <head-tool>
      <template #left>
        <div class="headTitle">{{ `秒杀` }}</div>
      </template>
      <template #right>
        <el-button type="primary" size="mini" @click="save()">保存</el-button>
        <!--                <el-button type="primary" size="mini" @click="backSave">保存并退出</el-button>-->
        <!--                <el-dropdown split-button size="mini">-->
        <!--                    审核-->
        <!--                    <el-dropdown-menu slot="dropdown">-->
        <!--                        <el-dropdown-item>反审核</el-dropdown-item>-->
        <!--                    </el-dropdown-menu>-->
        <!--                </el-dropdown>-->
        <el-button size="mini" @click="quit">退出</el-button>
      </template>
    </head-tool>
    <!-- 没容区域 -->
    <div class="seckill-detail">
      <div class="main">
        <el-form label-width="100px" :model="form" ref="formRef" :rules="rules">
          <!-- 基本信息 -->
          <ComCard :title="`基本信息`">
            <template #content>
              <basicInfo class="content" :form="form.seckillBody" seckill :disabled="form.seckillBody.auditStatus=='1'"></basicInfo>
            </template>
          </ComCard>
          <!-- 参与活动的商品 -->
          <ComCard :title="`参与活动的商品`">
            <template #content>
              <div class="goodsTable content">
                <ETable
                 :disabled="form.seckillBody.auditStatus=='1'"
                  :data="form"
                  activityType="102"
                  :tableColumnLabel="goodsTableLabel"
                  @selectGood="openSelectGoods"
                  @tableRow="tableRow"
                  @deleteGood="deleteGood"
                  @search="eTableSearch"
                  @pitchGoods="pitchGoods"
                >
                </ETable>
              </div>
            </template>
          </ComCard>
          <!-- 更多配置 -->
          <ComCard :title="`更多配置`">
            <template #content>
              <div class="moreConfig content">
                <el-form-item label="优惠叠加">
                  <el-checkbox 
                 :disabled="form.seckillBody.auditStatus=='1'"
                   v-model="form.seckillBody.isEnableCoupon"
                    >允许使用优惠券</el-checkbox
                  >
                </el-form-item>
                <el-form-item
                  label="适用门店"
                  class="rangeShop"
                  prop="seckillBody.usingInStoreType"
                  :rules="rules[`seckillBody.usingInStoreType`]"
                >
                  <el-radio-group 
                 :disabled="form.seckillBody.auditStatus=='1'"
                   v-model="form.seckillBody.usingInStoreType">
                    <el-radio
                      :label="dict.value"
                      v-for="dict in dict.type.fill_shop_range_type"
                      :key="dict.value"
                      :value="dict.value"
                      >{{ dict.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- 指定门店区域 -->
                <div
                  class="selectShop"
                  v-if="
                    form.seckillBody.usingInStoreType === '1' ||
                    form.seckillBody.usingInStoreType === '2'
                  "
                >
                  <div class="shopBtn marB10">
                    <el-button :disabled="form.seckillBody.auditStatus=='1'" size="mini" @click="openShopDialog">选择门店</el-button>
                  </div>
                  <!-- 门店表格 -->
                  <comTable
                  :disabled="form.seckillBody.auditStatus=='1'"
                    class="marB10"
                    :columnLabel="shopTableLable"
                    :tableData="form.shopItems"
                    @delTableRow="delShopRow"
                  ></comTable>
                </div>
                <el-form-item
                  prop="seckillBody.usingInTimeType"
                  :rules="rules[`seckillBody.usingInTimeType`]"
                  label="适用时间"
                  class="rangeTime"
                  label-width="100px"
                >
                  <el-radio-group :disabled="form.seckillBody.auditStatus=='1'" v-model="form.seckillBody.usingInTimeType">
                    <el-radio
                      :label="dict.value"
                      v-for="dict in dict.type.use_time_range_type"
                      :key="dict.value"
                      :value="dict.value"
                      >{{ dict.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="timeTable" v-if="form.seckillBody.usingInTimeType === '2'">
                  <div class="btn x-f marB10">
                    <el-button :disabled="form.seckillBody.auditStatus=='1'" size="mini" @click="openDateDialog">选择时间</el-button>
                  </div>
                  <!-- 时间表格 -->
                  <comTable
                  :disabled="form.seckillBody.auditStatus=='1'"
                    class="marB10"
                    :columnLabel="timeTableLable"
                    :tableData="form.specialDateItems"
                    @delTableRow="delTimeRow"
                  ></comTable>
                </div>
                <el-form-item label="限购数">
                  <el-checkbox :disabled="form.seckillBody.auditStatus=='1'" v-model="form.seckillBody.isEnablePurchaseLimit"
                    >开启</el-checkbox
                  >
                </el-form-item>
                <el-form-item v-if="form.seckillBody.isEnablePurchaseLimit">
                  <el-radio-group
                  :disabled="form.seckillBody.auditStatus=='1'"
                    v-model="form.seckillBody.limitedType"
                    class="purchaseLimit x-f"
                  >
                    <div class="x-f">
                      <el-radio :disabled="form.seckillBody.auditStatus=='1'" :label="1">活动限购数</el-radio>
                      <!-- 显示 -->
                      <el-form-item v-if="!(form.seckillBody.limitedType === 1)">
                        <el-input disabled></el-input>
                      </el-form-item>
                      <!-- 实际输入 -->
                      <el-form-item v-if="form.seckillBody.limitedType === 1">
                        <el-input
                         :disabled="form.seckillBody.auditStatus=='1'"
                          v-model="form.seckillBody.activityLimitedQty"
                          onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-f">
                      <el-radio  :disabled="form.seckillBody.auditStatus=='1'" :label="2">每日限购数</el-radio>
                      <!-- 显示 -->
                      <el-form-item v-if="!(form.seckillBody.limitedType === 2)">
                        <el-input disabled></el-input>
                      </el-form-item>
                      <!-- 实际输入 -->
                      <el-form-item v-if="form.seckillBody.limitedType === 2">
                        <el-input
                         :disabled="form.seckillBody.auditStatus=='1'"
                          v-model="form.seckillBody.dailyLimitedQty"
                          onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-radio-group>
                  <div class="tag">
                    活动限购数:当限购数为1时,所有活动商品每个买家只能购买1个商品:适用于低价抢购回馈客户的,限制每个客户只能抢购1个便宜的商品.
                  </div>
                  <div class="tag">
                    每日限购数:当限购数为1时,所有活动商品每个买家每天只能购买1个商品:第2天可以再次购买1个,适用于低价吸引顾客复购,增加商城活跃度.
                  </div>
                </el-form-item>
                <el-form-item label="预热">
                  <el-checkbox  :disabled="form.seckillBody.auditStatus=='1'" v-model="form.seckillBody.isEnablePreHeat"
                    >开启</el-checkbox
                  >
                </el-form-item>
                <el-form-item class="preheat" v-if="form.seckillBody.isEnablePreHeat">
                  <div class="tag">
                    开启后,活动会提前展示;例如活动在18:00开始,提前6个小时展示,秒杀活动则会在12:00展示在小程序端
                  </div>
                  提前
                  <el-input
                   :disabled="form.seckillBody.auditStatus=='1'"
                    v-model="form.beforeQtyHour"
                    onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                  ></el-input>
                  小时展示
                </el-form-item>
                <el-form-item
                  label="每日库存更新"
                  prop="seckillBody.dailyStockUpdates"
                  :rules="rules[`seckillBody.dailyStockUpdates`]"
                >
                  <el-switch  :disabled="form.seckillBody.auditStatus=='1'" v-model="form.seckillBody.dailyStockUpdates"></el-switch>
                  <div class="tag">
                    开启后,促销库存每天00:00会初始化为初始库存;例:促销库存为100件,今日售卖50件,剩余库存为50件;0点后,剩余库存会初始化为100件;
                  </div>
                </el-form-item>
              </div>
            </template>
          </ComCard>
        </el-form>
      </div>
    </div>

    <!-- 选择商品弹窗 -->
    <SelectGoods
      :queryCode="queryCode"
      :isShopp="true"
      :OpenGoods.sync="showGoodsDia"
      @getGoodsIds="pitchGoods"
    />
    <!-- 选择门店弹框 -->
    <SelectShop
      :showShopDialog="showShopDialog"
      @close="showShopDialog = false"
    ></SelectShop>
    <!-- 选择时间 -->
    <selectDate
      ref="selectDate"
      :OpenDate.sync="showDateDialog"
      @changeDate="changeDate"
    />
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import comTable from "@/views/components/com-table"; //表格组件
import ETable from "../components/e-table.vue"; //商品表格组件
import basicInfo from "../components/basicInfo.vue";
import SelectGoods from "@/views/O2OMall/marketing/components/SelectGoods.vue"; //选择商品弹窗组件
import SelectShop from "@/views/O2OMall/components/selectShop.vue";
import SelectDate from "@/views/O2OMall/components/selectDate.vue";
import { getBillNo } from "@/api/codeRule";
import {
  seckillGetDetail,
  seckillSave,
  seckillUpdate,
} from "@/api/O2OMall/marketing/seckill"; //基本信息区域组件
import { deepCopy } from "@/utils";
//表单
const form = {
  specialDateItems: [], //时间表格数据
  shopItems: [], //门店表格数据
  goodsItems: [{}], //商品表格数据
  seckillBody: {
    activityNo: "",
    activityName: "", // 活动名称
    activityTimes: [], // 开始时间/结束时间
    usingChannels: "1", //适用渠道
    payChannelItems: [0, 1], //支付方式
    deliveryType: "0", // 配送方式
    fetchTypeItems: [1, 2], // 取货方式
    limitedType: "", // 限购类型
    dailyLimitedQty: "", // 每日限购树
    isEnablePurchaseLimit: false, // 是否限购
    activityLimitedQty: "", // 活动限购树
    isEnableCoupon: false, // 优惠叠加
    usingInStoreType: "0", // 适用门店
    usingInTimeType: "0", // 适用时间
    isEnablePreHeat: false, // 是否预热
    beforeQtyHour: "", // 提前XX小时展示
    dailyStockUpdates: false, //  每日库存更新
  },
};
export default {
  name: "SeckillDetail",
  dicts: [
    "fill_shop_range_type", //适用门店范围
    "use_time_range_type", //适用时间范围
  ],
  components: {
    Dialog,
    comTable,
    ETable,
    basicInfo,
    SelectGoods,
    SelectShop,
    SelectDate,
  },
  data() {
    return {
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      queryCode: "",
      loading: false,
      onCreated: false,
      //门店表格字段
      shopTableLable: [
        { label: "编号", prop: "shopNo" },
        { label: "名称", prop: "shopName" },
      ],
      //时间范围表格字段
      timeTableLable: [{ label: "时间范围", prop: "dateTime" }],
      datetime: [], //拼团日期
      //商品可编辑表格字段
      goodsTableLabel: [
        { label: "商品名称", prop: "goodsName" },
        { label: "规格", prop: "goodsSpec" },
        { label: "商品条码", prop: "barcode" },
        { label: "单位", prop: "unitName" },
        { label: "秒杀价", prop: "seckillPrice", edit: true, precision: 2, min: 0 },
        {
          label: "活动库存",
          prop: "activityStockQuantity",
          edit: true,
          precision: 0,
          min: 0,
        },
        {
          label: "起购数",
          prop: "minPurchaseQuantity",
          edit: true,
          precision: 0,
          min: 0,
        },
        { label: "售价", prop: "salePrice" },
      ],
      //-----------------------------------------------------------
      showGoodsDia: false, // 选择商品弹框
      showShopDialog: false, // 选择门店弹框
      showDateDialog: false, // 选择时间弹框
      //表单
      form: deepCopy(form),
      rules: {
        // 'seckillBody.isEnableCoupon': [
        //     { required: true, message: '是否允许使用优惠券', trigger: ['blur', 'change'] }
        // ],
        "seckillBody.usingInStoreType": [
          { required: true, message: "请选择适用门店", trigger: ["blur", "change"] },
        ],
        "seckillBody.usingInTimeType": [
          { required: true, message: "请选择适用时间", trigger: ["blur", "change"] },
        ],
        "seckillBody.activityTimes": [
          { required: true, message: "请填写活动名称", trigger: ["change", "blur"] },
        ],
        // 'seckillBody.isEnablePurchaseLimit': [
        //     { required: true, message: '是否开启限购', trigger: ['blur', 'change'] }
        // ],
        // 'seckillBody.isEnablePreHeat': [
        //     { required: true, message: '是否开启预热', trigger: ['blur', 'change'] }
        // ],
      },
      isBack: false,
      seckillId: "", // 秒杀ID，编辑用
      timeId: undefined,
    };
  },
  activated() {
    if (this.onCreated) {
      this.onCreated = false;
    } else {
      this.form = deepCopy(form);
      this.$nextTick(() => {
        try {
          this.$refs.formRef.clearValidate();
        } catch (error) {}
        this.init();
      });
    }
  },
  created() {
    this.onCreated = true;
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.seckillId) {
        this.seckillId = this.$route.query.seckillId;
        this.getDetails();
      } else {
        this.getBillno();
      }
      this.timeId = Date.now(); //获取日期时间识别id
    },
    getBillno() {
      getBillNo(180202).then((res) => {
        this.form.seckillBody.activityNo = res;
      });
    },
    getDetails() {
      this.loading = true;
      seckillGetDetail({
        seckillId: this.seckillId,
      })
        .then((res) => {
          let { seckillBody, goodsItems, shopItems, specialDateItems } = res.data;
          this.form.seckillBody = seckillBody;
          this.form.goodsItems = goodsItems.length ? goodsItems : [{}];
          this.form.shopItems = shopItems.length ? shopItems : [];
          this.form.specialDateItems = specialDateItems.length
            ? this.$refs.selectDate.setAndGetData(specialDateItems)
            : [];
          console.log("详情：", this.form);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDate(e) {
      this.form.specialDateItems = e;
    },
    //选择商品方法
    openSelectGoods() {
      console.log("12321");
      this.showGoodsDia = true;
    },
    // 行操作
    tableRow(obj) {
      if (form.seckillBody.auditStatus!='1'&&form.seckillBody.auditStatus)return  this.$message.error('当前状态，不允许修改！')
      if (obj.type === "push") {
        this.form.goodsItems.push({});
      } else {
        if (this.form.goodsItems.length > 1) {
          this.form.goodsItems.splice(obj.index, 1);
        } else {
          this.form.goodsItems = [{}];
        }
      }
    },
    // 回车搜索商品
    eTableSearch(response, index) {
      if (Array.isArray(response.rows) && response.rows.length) {
        if (response.rows.length > 1) {
          this.tableIndex;
          this.queryCode = response.goodsNo;
          this.$nextTick(this.openSelectGoods);
          this.form.goodsItems[index].goodsNo = this.form.goodsItems[index].originGoodsNo;
        } else {
          this.pitchGoods(response.rows, index);
        }
      }
    },
    // 校验后删除不符合的商品
    deleteGood(goodsId) {
      this.form.goodsItems.forEach((item, index) => {
        if (item.goodsId == goodsId) {
          if (this.form.goodsItems.length > 1) {
            this.form.goodsItems.splice(index, 1);
          } else {
            this.form.goodsItems = [{}];
          }
        }
      });
    },
    // 选择商品
    pitchGoods(data, index = -1) {
      const arr = deepCopy(this.form.goodsItems);
      const isSplice = index >= 0;
      data.forEach((x, xidx) => {
        x.units
          ?.filter((y) => Number(y.status) === 0)
          ?.forEach?.((item, yidx) => {
            if (isSplice && xidx === 0 && yidx === 0) {
              // arr[index] = deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo })
              arr.splice(index, 1, deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo }));
              const oldItem = arr.find(
                (z, i) =>
                  z.goodsId === x?.goodsId && z.unitId === item?.unitId && i !== index
              );
              if (oldItem) {
                oldItem.goodsId = null;
              }
            } else {
              if (!arr.find((y) => y.goodsId === x.goodsId && y.unitId === item.unitId)) {
                arr.push(deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo }));
              }
            }
          });
      });
      this.form.goodsItems = arr.filter((x, i) => x.units?.length && x.goodsId);
    },
    goodUnitsDivide(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let units = data[i].units;
        for (let j = 0; j < units.length; j++) {
          console.log("选择商品1===", data[i]);
          data[i].unitId = units[j].unitId;
          data[i].barcode = units[j].barcode;
          data[i].salePrice = units[j].salePrice;
          let obj = data[i];
          arr.push({ ...obj });
        }
      }
      return arr;
    },
    handleEvent(e, row) {
      const data = row.formData.table.check;
      this.form.shopItems = [
        ...this.form.shopItems,
        ...data
          .filter((x) => !this.form?.shopItems?.find((y) => y.shopId === x.shopId))
          .map((x) => ({
            shopId: x.shopId,
            shopName: x.shopName,
            shopNo: x.shopNo,
          })),
      ].filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.shopNo === undefined)) ||
          !(item.shopNo === undefined)
      );
    },
    //打开选择门店弹框
    openShopDialog() {
      // handleEvent('openShopDialog', { curTable: 'shopOptions' })
      this.dialogOptions = {
        title: "选择门店",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "o2oShop" }),
      };
      // this.showShopDialog = true;
    },
    // 删除门店
    delShopRow(row) {
      this.form.shopItems.forEach((item, index) => {
        if (item.shopId == row.shopId) {
          this.form.shopItems.splice(index, 1);
        } else {
          this.form.shopItems = [{}];
        }
      });
    },
    // 打开时间选择弹框
    openDateDialog() {
      this.showDateDialog = true;
    },
    // 选择时间
    getDateChangeDetail(value) {
      console.log("111选择时间段", value);
      this.showDateDialog = false;
      let Arr = [];
      value.forEach((v) => {
        //声明属性
        // 第一行
        let weekText1;
        if (v.specialDateWeekMode) {
          if (v.specialDateWeekMode === "0") {
            weekText1 = "仅单周";
          } else if (v.specialDateWeekMode === "1") {
            weekText1 = "仅双周";
          } else if (v.specialDateWeekMode === "2") {
            weekText1 = `仅每月第${v.specialDateWeekNum}周`;
          }
          this.timeId = this.timeId + 1;
          const id = this.timeId + "";
          this.$set(v, "dateTime", weekText1); //dateTime 日期时间共用字段
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 0); //日期每一行类型
          this.form.specialDateItems.push(v);
        } else if (v.specialDateWeeksArr && v.specialDateWeeksArr.length > 0) {
          // 第二行  (星期转换文字)
          let array = [...v.specialDateWeeksArr];
          for (let index = 0; index < array.length; index++) {
            if (array[index] === "1") {
              array[index] = "星期一";
            } else if (array[index] === "2") {
              array[index] = "星期二";
            } else if (array[index] === "3") {
              array[index] = "星期三";
            } else if (array[index] === "4") {
              array[index] = "星期四";
            } else if (array[index] === "5") {
              array[index] = "星期五";
            } else if (array[index] === "6") {
              array[index] = "星期六";
            } else if (array[index] === "7") {
              array[index] = "星期日";
            }
          }
          let val = array.toString();
          this.timeId = this.timeId + 2;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 1);
          this.form.specialDateItems.push(v);
        } else if (v.isTopMonthPeriod && v.isDownMonthPeriod) {
          // 第三行
          const val = `仅每月第${v.topFromDay}至${v.topToDay}天,每月最后${v.downFromDay}至${v.downToDay}天`;
          this.timeId = this.timeId + 3;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 2);
          this.form.specialDateItems.push(v);
        } else if (v.isTopMonthPeriod) {
          const val = `仅每月第${v.topFromDay}至${v.topToDay}天`;
          this.timeId = this.timeId + 3;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 2);
          this.form.specialDateItems.push(v);
        } else if (v.isDownMonthPeriod) {
          const val = `每月最后${v.downFromDay}至${v.downToDay}天`;
          this.timeId = this.timeId + 3;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 2);
          this.form.specialDateItems.push(v);
        } else if (v.specialDayArr && v.specialDayArr.length > 0) {
          // 第四行
          v.specialDayArr.sort(); //进行排序
          let val = "";
          //转换number类型,进行排序
          v.specialDayArr.forEach((i) => {
            val += i + ",";
          });
          val = val.slice(0, val.length - 1);
          val = `指定日期: 每月${val}日`;
          this.timeId = this.timeId + 4;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 3);
          this.form.specialDateItems.push(v);
        } else if (v.specialBegTime) {
          // 第五行
          const val = `时间范围：${v.specialBegTime}--${v.specialEndTime}`;
          this.timeId = this.timeId + 5;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 4);
          this.form.specialDateItems.push(v);
        } else if (v.specialTimeList) {
          let val = "";
          v.specialTimeList.forEach((item) => {
            let time = item.timeItem[0] + " - " + item.timeItem[0];
            val = val != "" ? val + "，" + time : time;
          });
          this.timeId = this.timeId + 5;
          const id = this.timeId + "";
          this.$set(v, "dateTime", val);
          this.$set(v, "id", id);
          this.$set(v, "specialDateType", 4);
          this.form.specialDateItems.push(v);
        }
      });
    },
    save() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (this.form.seckillBody.activityName == "") {
            return this.$message.warning("请输入活动名称");
          }
          if (!this.form.goodsItems[0].goodsNo) {
            return this.$message.warning("请添加活动商品");
          }
          console.log("主键ID", this.form.seckillBody.seckillId);
          this.form.seckillBody.usingChannels = "1";
          if (
            this.form.seckillBody.seckillId == undefined ||
            this.form.seckillBody.seckillId == "undefined"
          ) {
            seckillSave(this.form).then((res) => {
              this.$message.success("保存成功");
              this.$router.back();
            });
          } else {
            seckillUpdate(this.form).then((res) => {
              this.$message.success("修改成功");
              this.$router.back();
            });
          }
          // if (this.isBack) {
          //     this.form.seckillBody.activityNo = ''
          //     this.isBack = false
          //     this.$router.back()
          // }
        } else {
        }
      });
    },
    // 退出
    quit() {
      this.$confirm("还未保存是否在退出前保存当前内容？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.save();
        })
        .catch(() => {
          this.$router.go(-1);
        });
    },
    // 保存并退出
    backSave() {
      this.isBack = true;
      this.save();
    },
    refresh() {
      this.form = deepCopy(form);
      this.getBillno();
    },
    delTimeRow(item) {
      this.form.specialDateItems.forEach((time, index) => {
        if (item.id == time.id) {
          this.form.specialDateItems.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.seckill-detail {
  background-color: #eaeaea;
  padding-top: 50px;

  //卡片内容
  .content {
    padding: 20px 20px;
    padding-top: 0;
  }

  //表单每一项校验提醒的高度
  .el-form-item {
    margin-bottom: 10px;
  }

  // 基本信息
  .basicInfo {
    .el-form-item {
      width: 500px;
      margin-right: 10px;

      ::v-deep.el-date-editor--datetimerange.el-input__inner {
        width: 430px;
      }
    }

    .payWay,
    .disWay,
    .rangeShop,
    .rangeTime {
      margin-bottom: 10px;
    }
  }

  //更多配置
  .moreConfig {
    // 限购数
    .purchaseLimit {
      .el-radio {
        margin-right: 0;
      }

      .el-input {
        margin-left: 10px;
        margin-right: 30px;
        width: 100px;
      }
    }

    //预热
    .preheat {
      .el-input {
        width: 100px;
        margin: 0 5px;
      }
    }
  }

  //提示的文字
  .tag {
    margin-bottom: 0;
    color: #a4a5a6;
    line-height: 25px;
  }
}
</style>
