<template>
  <!-- 添加奖品弹出框 -->
  <div class="prizeFrame">
    <el-dialog
      title="添加奖品"
      :visible.sync="OpenPrize"
      :before-close="close"
      width="40%"
      v-dialogDrag="true"
    >
      <el-form label-width="75px">
        <el-form-item label="奖品名称">
          <el-input
            type="text"
            placeholder="最多可填写8个字"
            v-model="text"
            maxlength="8"
            style="width: 160px"
            show-word-limit
          >
          </el-input>
        </el-form-item>

        <el-form-item label="奖品名称">
          <div>
            <el-radio-group v-model="form.jpType">
              <el-radio :label="1">积分</el-radio>
              <el-radio :label="2">余额</el-radio>
              <el-radio :label="3">优惠券</el-radio>
              <!-- <el-radio :label="4">商品</el-radio> -->
            </el-radio-group>
            <div class="CouponTable marT10 marB10">
              <el-input v-if="form.jpType === 1"></el-input>
              <el-input v-else-if="form.jpType === 2"></el-input>
              <el-table
                v-if="form.jpType === 3"
                class="couponTable"
                ref="couponTable"
                :data="form.couponLargessItem"
                border
                max-height="300"
                row-key="couponCaseId"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column align="center" width="80">
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i v-if="scope.row.hoverRow" class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券名称"
                  align="center"
                  prop="couponCaseName"
                  show-overflow-tooltip
                  key="couponCaseName"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  label="优惠券类型"
                  align="center"
                  prop="couponCaseTypeName"
                  key="couponCaseTypeName"
                  width="180"
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.coupon_case_type"
                      :value="scope.row.couponCaseType"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="有效期时间"
                  align="center"
                  prop="expirationDate"
                  key="expirationDate"
                  :show-overflow-tooltip="true"
                  width="180"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="奖品图片">
          <div class="x-end">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleLogoSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.goodsUrl" :src="form.goodsUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div v-if="form.goodsUrl" class="updateImg">更换图片</div>
            </el-upload>
            <div class="picBtn marL10">恢复默认图</div>
          </div>
          <div class="tag" style="color: #a4a5a6">
            建议尺寸100*100px, 支持jpg、png、jpeg格式。
          </div>
        </el-form-item>
        <el-form-item label="发放总量">
          <div class="x-f">
            <el-input></el-input>
            <div>份</div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="OpenPrize = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PrizeFrame",
  props: {
    //奖品框开关
    OpenPrize: {
      type: Boolean,
      default: false,
    },
  },
  dicts: [
    "coupon_mode", //优惠券形式
    "coupon_case_type", //优惠券类型
  ],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      form: {
        jpType: 1,
        couponLargessItem: [{}],
      },
    };
  },
  methods: {
    /** 图片上传之前的尺寸校验 */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      return isLt2M;
    },
    /** 上传LOGO图片方式 */
    handleLogoSuccess(res, file) {
      this.form.goodsUrl = file.response.data.accessUrl;
      this.iskey = !this.iskey;
      this.$forceUpdate();
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      //获取当前行下标
      let eleIndex = this.form.couponLargessItem.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(JSON.stringify(this.form.couponLargessItem));
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.form.couponLargessItem = JSON.parse(JSON.stringify(Arr));
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.couponLargessItem.length; index++) {
        const element = this.form.couponLargessItem[index];
        element["hoverRow"] = false;
      }
    },
    //关闭弹出框回调
    close() {
      this.$emit("update:OpenPrize", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.prizeFrame {
  .el-radio {
    width: 60px;
  }
  .el-input {
    width: 120px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  //奖品名称输入框宽度
  .nameInput {
    width: 260px;
    margin-bottom: 0px;
  }
  //优惠券表格
  .couponTable {
    .el-input {
      width: 100%;
    }
  }
  //上传图片样式
  .avatar-uploader {
    width: 100px;
    height: 100px;
    position: relative;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .updateImg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  //恢复默认图按钮
  .picBtn {
    color: #409eff;
    cursor: pointer;
  }
}
</style>
